import { SliceZone } from "@prismicio/react";
import { createClient } from "../prismicio";
import { components } from "../slices";
import { Meta } from "@/components";
import CardGrid from "@/slices/CardGrid";
import extractSlices from "@/Utility/extractSlices";

export default function Home({
	page,
	courseCards,
	equipmentCards,
	equipmentCategoriesCards,
}) {
	const courseCardData = extractSlices(courseCards.data.slices, "courses");
	const equipmentCardsData = extractSlices(
		equipmentCards.data.slices,
		"equipment"
	);
	const equipmentCategoriesCardsData = extractSlices(
		equipmentCategoriesCards.data.slices,
		"equipmentCategories"
	);

	return (
		<>
			<Meta
				metaTitle={page?.data?.meta_title}
				metaDescription={page?.data?.meta_description}
				socialShareImage={page?.data?.meta_image?.url}
			/>
			<main>
				<SliceZone
					slices={page.data.slices}
					components={{
						...components,
						card_grid: (props) => {
							if (props?.slice?.variation == "courses")
								return <CardGrid {...props} courseCards={courseCardData} />;
							if (props?.slice?.variation == "equipment")
								return (
									<CardGrid {...props} equipmentCards={equipmentCardsData} />
								);
							if (props?.slice?.variation == "equipmentCategories")
								return (
									<CardGrid
										{...props}
										equipmentCategoriesCards={equipmentCategoriesCardsData}
									/>
								);
							else return <CardGrid {...props} />;
						},
					}}
				/>
			</main>
		</>
	);
}

export async function getStaticProps({ previewData }) {
	const client = createClient({ previewData });
	const page = await client.getSingle("home");
	const nav = await client.getSingle("nav");
	const footer = await client.getSingle("footer");

	const courseCards = await client.getSingle("home", {
		graphQuery: `{
				home {
					slices {
						...on card_grid {
							variation {
								...on courses {
									primary{
										heading
									}
									items {
										reference_product {
											...on course {
												...courseFields
											}
										}
									}
								}
							}
						}
					}
				}
			}`,
	});

	const equipmentCards = await client.getSingle("home", {
		graphQuery: `{
				home {
					slices {
						...on card_grid {
							variation {
								...on equipment {
									primary{
										heading
									}
									items {
										reference_product {
											...on product {
												...productFields
											}
										}
									}
								}
							}
						}
					}
				}
			}`,
	});

	const equipmentCategoriesCards = await client.getSingle("home", {
		graphQuery: `{
				home {
					slices {
						...on card_grid {
							variation {
								...on equipmentCategories {
									primary{
										heading
									}
									items {
										reference_product {
											...on equipment_category {
												...equipment_categoryFields
											}
										}
									}
								}
							}
						}
					}
				}
			}`,
	});

	return {
		props: {
			nav,
			page,
			footer,
			courseCards,
			equipmentCards,
			equipmentCategoriesCards,
		},
	};
}
